<template>
	<div id="aboutUs" ref="aboutUs">
	<Top :backBg="img" title="艾瑞数云" des="助力产业高质量发展" />
	<!-- 公司简介 -->
	<div
		class="brainPart bg-[url(@/assets/imgs/aboutUs/aboutUs_bg1.png)]  bg-bottom  bg-no-repeat bg-contain w-full  bg-[#F2F7FC]">
		<div class=' flex flex-col  content-start '>
			<div class="h-[80px] mt-[70px] w-full">
				<TitleDes title="公司简介" />
			</div>
			<div class="w-[750px] mx-[auto] px-[58px] pb-[100px]">
				<div class="mt-[53px]  text-left text-[26px] text-[#666] leading-8">
					艾瑞数云为国内首家咨询领域上市公司苏交科集团(股票代码:300284)智慧板块核心企业华立科技投资的产业数字科技公司。
				</div>
				<div class=" text-left text-[26px] text-[#666] leading-8">
					公司拥有一支由人工智能、大数据、产业研究、产业运营资深专家组成的跨界团队，以产业数据为基石，以大模型为技术支撑，秉承“研•判•招•管•服•培”的全栈产业发展理念，致力于成为服务产业发展的综合服务商。目前，公司已相继推出产研AI智能体、产发AI智能体、哒达招商、哒达企服、哒达出海、产业金脑、园区综合服务平台等七大核心产品。
       				<br>期待与您共创数据驱动的产业未来!
				</div>
			</div>
		</div>
	</div>
 
	<div class=' flex flex-wrap flex-col w-full h-[930px]  pt-[70px]'>
		<TitleDes title="联系我们"  />
		<div class="w-[100%]  flex flex-wrap mt-[35px]">
			<div
				class="w-[695px] h-[400px] mx-[auto]  bg-no-repeat bg-contain bg-center  bg-[url(@/assets/imgs/aboutUs/aboutUs_map.png)]">

			</div>
			<div
				class="chatCard w-full h-[335px]  p-[40px] ">
				<div class=" text-[#666] text-[28px]  flex flex-wrap justify-between ">
					<div class=" w-full flex flex-nowrap">
						<div
							class="bg-[url(@/assets/imgs/aboutUs/aboutUs_icon1.png)]  bg-center  bg-no-repeat bg-contain w-[51px] h-[51px] mr-4">
						</div>
						<div class="text-[28px] text-[#666]">电话：19157888002</div>
					</div>
					<div class=" w-full flex flex-nowrap mt-[23px] mb-[23px]">
						<div
							class="bg-[url(@/assets/imgs/aboutUs/aboutUs_icon2.png)]  bg-center  bg-no-repeat bg-contain w-[51px] h-[51px] mr-4">
						</div>
						<div class="text-[28px] text-[#666]"> 邮箱：admin@idicc.cn</div>
					</div>
					<div class=" w-full flex flex-nowrap">
						<div
							class="bg-[url(@/assets/imgs/aboutUs/aboutUs_icon3.png)] bg-center bg-no-repeat bg-contain w-[51px] h-[51px]  mr-4">
						</div>
						<div class="  text-[28px] text-[#666]"> 地址：浙江省杭州市余杭区仓前街道文一西路1324号</div>
					</div>
				</div>

			</div>
		
		</div>
	</div>
</div>
</template>
<script setup>
import Top from '@/components/top-img/index.vue';
import TitleDes from '@/components/title-des/index.vue';
import img from '@/assets/imgs/aboutUs/aboutUs_top.png';
import { watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter();
const aboutUs = ref(null)
watch(
	() => router.currentRoute.value.params.id,
	(newValue, oldValue) => {
		let value = newValue
		if (value === '02') {
		setTimeout(function() {
			window.scrollTo({
      top: 600,
      behavior: "smooth"
    })
  // window.scrollTo(0,600);
}, 1);
		}
	})
onMounted(() => {
	let value = router.currentRoute.value.params.id;
	if (value === '02') {
		setTimeout(function() {
  // window.scrollTo(0,600);
	window.scrollTo({
      top: 600,
      behavior: "smooth"
    })
}, 1);
	}
})
</script>

<style scoped>
 
</style>